<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>

<template>
  <div>
    <Head-section></Head-section>
    <br> 
    <div class="container">
     
    <div class="mt-3">
         <div class="mt-4">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="12">
                <b-card-img
                  :src=banner_url
                  alt="About AIM"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
              <b-col md="12">
                <b-card-body title class="card-body-sytle">
                
                  <b-card-text v-if="iszh">
                  <H1>关于我们</H1>
                  <br>
                  <H5>
                   DTIP是一家专注于动画制作和IP运营的制作服务提供商，成功开发了“梦廊DREAM TIME”解密游戏发布于STEAM平台，拥有强大的开发与运营团队，基于IP打造的动漫原创产品拥有更大的市场前景与溢价空间，主营类目为服装，饰品。
                  </H5>
                   <br>
                   <H5>
                   如果您需要授权，以及对产品感兴趣的人，请与我们联系。
                   </H5>
                   <br>
                   <br>
                  </b-card-text>

                  <b-card-text v-else>
                  <H1>About DTIP</H1>
                  <br>
                  <H5>
                   DTIP is a production service provider focusing on animation production and IP operation. It has successfully developed the "dream time" decryption game and released it on the steam platform. It has a strong development and operation team. The original animation products based on IP have greater market prospects and premium space. Its main categories are clothing and accessories.
                  </H5>
                   <br>
                   <H5>
                   If you need authorization, and those who are interested in the product, please contact us.
                   </H5>
                   <br>
                   <br>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
         
        </div>
        </div>
    <Foot-section></Foot-section>
  </div>
</template>

<script>
import HeadSection from "./HeadSection.vue";
import FootSection from "./FootSection.vue";
import banner from "../assets/banner1.jpg";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AboutBluedio",
  components: {
    HeadSection,
    FootSection
  },
  metaInfo() {
    return {
       title: this.$t("message.Bluedio_Mall") + " | About US",
       titleTemplate: "%s",
      meta: [
         {
          name: "keywords",
          content: "About US",
        }
      ],
    };
  },
  data() {
    return {
      banner_url:banner,
    };
  },
  created() {
    
  },
  computed: {
    ...mapGetters([
      "lan","iszh"
    ])
  },

  watch: {
  },
  methods: {
  },
};
</script>
